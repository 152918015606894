import { connect } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { iconPickerSelectors } from '../../../../redux/iconPicker/slice';
import { IconPickerTabs } from '.';

export type IconPickerTabsProps = ReturnType<typeof mapStateToProps>;

export type IconPickerContainerOwnProps = {
  isHeightCondensed?: boolean;
};

const mapStateToProps = (
  state: RootState,
  ownProps: IconPickerContainerOwnProps,
) => ({
  tabOrder: iconPickerSelectors.getTabOrder(state),
  ...ownProps,
});

export const IconPickerTabsContainer = connect(mapStateToProps)(IconPickerTabs);
