import React, { ReactElement } from 'react';
import {
  publicSessionApi,
  usePostPublicSessionHwActionMutation,
} from '../../../../../../../../redux/views/session-view/session/api/publicApi';
import {
  getConsoleHrefFromSessionDetails,
  getDeviceIdFromSessionDetails,
  getPduControlFromSessionDetails,
} from '../../../../../utils';
import { ControlContainerProps } from './container';
import { HWControls } from '.';

type ConnectedPublicControlProps = ControlContainerProps &
  FetchPublicSessionArgs;

export function ConnectedPublicControl({
  accessKey,
  nodeUid,
  sessionUid,
  versionUid,
}: ConnectedPublicControlProps): ReactElement {
  const { consoleHref, deviceId, pduControl } =
    publicSessionApi.endpoints.getPublicSession.useQueryState(
      { accessKey, sessionUid, versionUid },
      {
        selectFromResult: ({ data }) => ({
          consoleHref: getConsoleHrefFromSessionDetails(
            (
              data?.diagram.nodes.filter(
                (node) => node.uid === nodeUid,
              )[0] as SessionHwNode
            ).sessionDetails,
          ),
          deviceId: getDeviceIdFromSessionDetails(
            (
              data?.diagram.nodes.filter(
                (node) => node.uid === nodeUid,
              )[0] as SessionHwNode
            ).sessionDetails,
          ),
          pduControl: getPduControlFromSessionDetails(
            (
              data?.diagram.nodes.filter(
                (node) => node.uid === nodeUid,
              )[0] as SessionHwNode
            ).sessionDetails,
          ),
        }),
      },
    );
  const [postSessionHwAction] = usePostPublicSessionHwActionMutation();

  const postHwAction = (action: SessionHwActions) => {
    if (!pduControl || !deviceId) return;

    postSessionHwAction({
      accessKey,
      action,
      deviceId: deviceId,
      sessionId: sessionUid,
      topologyVersion: { uid: versionUid },
    });
  };

  return (
    <HWControls
      consoleHref={consoleHref}
      postHwAction={postHwAction}
      pduControl={pduControl}
    />
  );
}
