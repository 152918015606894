import React, { ReactElement } from 'react';
import { publicSessionApi } from '../../../../../../../../redux/views/session-view/session/api/publicApi';
import { getSessionInformationFromSession } from './utils';
import { SessionInformation } from '.';

export function ConnectedPublicSessionInformation({
  accessKey,
  sessionUid,
  versionUid,
}: FetchPublicSessionArgs): ReactElement {
  const { sessionInformation, isError, isLoading, isFetching } =
    publicSessionApi.endpoints.getPublicSession.useQueryState(
      { accessKey, sessionUid, versionUid },
      {
        selectFromResult: ({ data, isLoading, isFetching, isError }) => ({
          isError,
          isFetching,
          isLoading,
          sessionInformation: getSessionInformationFromSession(data),
        }),
      },
    );

  return (
    <SessionInformation
      sessionInformation={sessionInformation}
      isLoading={isLoading || isFetching}
      isError={isError}
    />
  );
}
