import React, { ReactElement } from 'react';
import { useSessionUserContext } from '../../../../../../../../../../hooks/use-session-user-context';
import { ConnectedDCloudPublicIP } from './dCloudContainer';
import { ConnectedPublicPublicIP } from './publicContainer';

export function PublicIPContainer(): ReactElement {
  const { accessKey, sessionUid, versionUid } = useSessionUserContext();

  if (accessKey) {
    return (
      <ConnectedPublicPublicIP
        accessKey={accessKey}
        sessionUid={sessionUid}
        versionUid={versionUid}
      />
    );
  }

  return (
    <ConnectedDCloudPublicIP sessionUid={sessionUid} versionUid={versionUid} />
  );
}

export type PublicIProps = {
  sessionPublicAddresses?: SessionPublicAddress[];
  isLoading: boolean;
  isError: boolean;
};
