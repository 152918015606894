import { dcloudApi } from '../../../../api';
import {
  PUBLIC_PATH,
  SESSION_HW_ACTIONS_PATH,
  SESSION_VM_ACTIONS_PATH,
  SESSIONS_PATH,
  VM_STATUS,
} from '../../../../../api/paths';
import i18n from '../../../../../i18n';
import { TAGS } from '../../../../api/constants';
import { decorateSessionViewConnections, getVmActionMessage } from '../utils';

export const publicSessionApi = dcloudApi.injectEndpoints({
  endpoints: (build) => ({
    getPublicSession: build.query<Session, FetchPublicSessionArgs>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.SESSIONS],
      query: ({ accessKey, sessionUid, versionUid }) => ({
        errorMessage: i18n.t('sessions.fetch.error'),
        method: 'get',
        url: `${PUBLIC_PATH}${SESSIONS_PATH}/${sessionUid}?versionUid=${versionUid}&publicAccessKey=${accessKey}`,
      }),
      transformResponse: (response: SessionResponse) =>
        decorateSessionViewConnections(response),
    }),
    getPublicSessionVmStatus: build.query<
      SessionVmStatus,
      FetchPublicSessionVmStatusArgs
    >({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.SESSION_VM_STATUS],
      query: ({ accessKey, sessionUid, versionUid, mor }) => ({
        errorMessage: i18n.t('sessions.fetchVmStatus.error'),
        method: 'get',
        url: `${PUBLIC_PATH}${SESSIONS_PATH}/${sessionUid}${VM_STATUS}?versionUid=${versionUid}&mor=${mor}&publicAccessKey=${accessKey}`,
      }),
    }),
    postPublicSessionHwAction: build.mutation<
      SessionHwActionResponse,
      PublicSessionHwActionArgs
    >({
      query: ({
        accessKey,
        suppressSuccessToast,
        suppressErrorToast,
        ...rest
      }) => ({
        data: rest,
        errorMessage: i18n.t('sessions.postHwAction.error'),
        method: 'post',
        successMessage: suppressSuccessToast
          ? undefined
          : i18n.t('sessions.postHwAction.success'),
        suppressErrorToast: suppressErrorToast,
        url: `${PUBLIC_PATH}${SESSION_HW_ACTIONS_PATH}?publicAccessKey=${accessKey}`,
      }),
    }),
    postPublicSessionVmAction: build.mutation<
      SessionVmActionResponse,
      PublicSessionVmActionArgs
    >({
      query: ({
        accessKey,
        suppressSuccessToast,
        suppressErrorToast,
        ...rest
      }) => ({
        data: rest,
        errorMessage: i18n.t('sessions.postVmAction.error'),
        method: 'post',
        successMessage: suppressSuccessToast
          ? undefined
          : getVmActionMessage(rest.action),
        suppressErrorToast: suppressErrorToast,
        url: `${PUBLIC_PATH}${SESSION_VM_ACTIONS_PATH}?publicAccessKey=${accessKey}`,
      }),
    }),
  }),
});

export const {
  useGetPublicSessionQuery,
  useGetPublicSessionVmStatusQuery,
  usePostPublicSessionHwActionMutation,
  usePostPublicSessionVmActionMutation,
} = publicSessionApi;
