/* eslint react/display-name:0, react/prop-types: 0 */
import * as d3 from 'd3';
import React, {
  forwardRef,
  KeyboardEvent,
  MouseEvent,
  PropsWithChildren,
} from 'react';
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import classnames from 'classnames';
import { useParams } from 'react-router-dom';
import { useDiagramContext } from '../DiagramProvider';
import { determineIfRadialGradientRequired } from '../Nodes/utils/utils';
import styles from './Node.module.css';
import { useLogicalConnectionGuideLineHelper } from './hooks/use-logical-connection-guide-line-helper';
import { useNodeOnClickHandler } from './hooks/use-node-on-click-handler';

export interface NodeProps {
  className?: string;
  uid?: string;
  isSelected?: boolean;
  isDisabled?: boolean;
  isHidden?: boolean;
  onClick?: (event: MouseEvent<HTMLElement> | undefined) => void;
  isReadOnly: boolean;
  onKeyUp?: (event: KeyboardEvent<HTMLInputElement>) => void;
  isRemovable?: boolean;
  isDrawingType?: boolean;
  isBoxNode?: boolean;
  isIconNode?: boolean;
  isVmNode?: boolean;
  isLineNode?: boolean;
  isCommonNode?: boolean;
  isAddingLogicalConnection?: boolean;
  isCreatingLogicalConnection?: boolean;
  isDrawingLogicalConnectionFrom?: string;
  setIsDrawingLogicalConnectionFrom?: ActionCreatorWithOptionalPayload<
    string | undefined
  >;
  createConnection?: RTKMutation<CreateConnectionPayload, undefined>;
  isConnectionAllowed?: boolean;
  iconName?: string;
}

export const Node = forwardRef<HTMLDivElement, PropsWithChildren<NodeProps>>(
  (
    {
      className,
      children,
      onClick,
      isReadOnly,
      isDisabled = false,
      isHidden = false,
      isSelected = false,
      uid,
      onKeyUp,
      isRemovable,
      isCommonNode,
      isDrawingType,
      isBoxNode,
      isIconNode,
      isVmNode,
      isLineNode,
      isAddingLogicalConnection = false,
      isDrawingLogicalConnectionFrom,
      setIsDrawingLogicalConnectionFrom,
      isConnectionAllowed = false,
      isCreatingLogicalConnection,
      iconName,
    },
    ref,
  ) => {
    const removableNodeProps = isRemovable ? { tabIndex: 0 } : {};

    const { uid: topologyUid } = useParams<EditTopologyParams>();
    const { toggleSelectedItem } = useDiagramContext();

    useLogicalConnectionGuideLineHelper({
      isAddingLogicalConnection,
      isCreatingLogicalConnection: isCreatingLogicalConnection || false,
      isDrawingLogicalConnectionFrom,
      isReadOnly,
      nodesCanvas: d3.select('#nodesCanvas'),
      uid,
    });

    const { handleOnClick } = useNodeOnClickHandler();

    const onNodeClick = (event: MouseEvent<HTMLDivElement>) => {
      handleOnClick({
        event,
        isAddingLogicalConnection,
        isConnectionAllowed,
        isDrawingLogicalConnectionFrom,
        onClick,
        setIsDrawingLogicalConnectionFrom,
        toggleSelectedItem,
        topologyUid,
        uid,
      });
    };

    const isRadialGradientNeeded = determineIfRadialGradientRequired({
      iconName,
      isHidden,
    });

    return (
      <div
        {...removableNodeProps}
        onKeyUp={onKeyUp}
        data-component={uid}
        className={classnames(styles.root, className, {
          [styles.editable]: !isReadOnly,
          [styles.selected]: isSelected,
          [styles.boxNode]: isBoxNode,
          [styles.iconNode]: isIconNode,
          [styles.disabled]: isDisabled,
          [styles.hidden]: isHidden && !isCommonNode,
          [styles.removableNode]: isRemovable,
          [styles.drawNode]: isDrawingType,
          [styles.noHover]: isDrawingType && isReadOnly,
          [styles.vmNode]: isVmNode,
          [styles.lineNode]: isLineNode,
          [styles.notAllowed]:
            isAddingLogicalConnection && !isConnectionAllowed,
          [styles.radialGradient]: isRadialGradientNeeded,
        })}
        ref={ref}
        onClick={onNodeClick}
      >
        {children}
      </div>
    );
  },
);
