import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { RootState } from '../../../../../../../../../../redux/store';
import { setVirtualMachineToConfigure } from '../../../../../../../../../../redux/configuration/virtual-machine-entities/slice';
import { TemplatesTable } from '.';

type TemplatesTableOwnProps = {
  loading: boolean;
  templateVms: InventoryVirtualMachine[];
  setShowMoreInfoForVmTemplate: ActionCreatorWithPayload<
    InventoryVirtualMachine | undefined,
    string
  >;
  topologyRemoteAccessCount: number;
  setShowRemoteAccessCountExceededModal?: ActionCreatorWithPayload<
    boolean,
    string
  >;
  isDiagramView: boolean;
};

const mapDispatchToProps = {
  setVirtualMachineToConfigure,
};

const mapStateToProps = (_: RootState, ownProps: TemplatesTableOwnProps) => ({
  ...ownProps,
});

export type TemplatesTableProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

export const TemplatesTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TemplatesTable);
