import React, { ReactElement } from 'react';
import { Checkbox } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import { CommonIconEditModalCheckboxProps } from './container';
import { useInitialiseCheckbox } from './hooks/useInitialiseCheckbox';

export const CommonIconEditModalCheckbox = ({
  setIsCommonIconNodeHiddenInEditModal,
  isHidden = false,
  isCommonIconNodeHiddenInEditModal,
  setIsCommonIconNodeHiddenInEditModalOnRender,
}: CommonIconEditModalCheckboxProps): ReactElement => {
  const { t } = useTranslation();

  const handleOnChange = () => {
    setIsCommonIconNodeHiddenInEditModal(!isCommonIconNodeHiddenInEditModal);
  };

  useInitialiseCheckbox({
    isHidden,
    setIsCommonIconNodeHiddenInEditModal,
    setIsCommonIconNodeHiddenInEditModalOnRender,
  });

  return (
    <Checkbox
      onChange={handleOnChange}
      checked={isCommonIconNodeHiddenInEditModal}
      label={t('common.hideFromRunningSessions')}
      aria-label="Hide from running sessions"
    />
  );
};
