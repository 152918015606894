import React, { ReactElement } from 'react';
import { IconPickerProps } from './container';
import { IconPickerButtonsContainer } from './components/IconPickerButtons/container';
import { IconPickerTabsContainer } from './components/IconPickerTabs/container';
import { useInititialiseIconPicker } from './hooks/useInititialiseIconPicker';

export function IconPicker({
  onCancelButtonClick,
  onPrimaryButtonClick,
  showButtons = false,
  setShowButtons,
  setSelectedIcon,
  existingSelectedIcon,
  setInitialSelectedIcon,
  reset,
  tabOrder,
  availableIcons,
  setAvailableIcons,
  setTabOrder,
  isHeightCondensed,
}: IconPickerProps): ReactElement {
  useInititialiseIconPicker({
    availableIcons,
    existingSelectedIcon,
    reset,
    setAvailableIcons,
    setInitialSelectedIcon,
    setSelectedIcon,
    setShowButtons,
    setTabOrder,
    showButtons,
    tabOrder,
  });

  return (
    <>
      <IconPickerTabsContainer isHeightCondensed={isHeightCondensed} />
      <IconPickerButtonsContainer
        onCancelButtonClick={onCancelButtonClick}
        onPrimaryButtonClick={onPrimaryButtonClick}
      />
    </>
  );
}
