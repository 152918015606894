import React, { ReactElement } from 'react';
import { publicSessionApi } from '../../../../../../redux/views/session-view/session/api/publicApi';
import { getIconDataFromNodeDetails } from './utils/utils';
import { IconInfoContainerProps } from './container';
import { IconInfo } from '.';

type ConnectedPublicIconInfoProps = IconInfoContainerProps &
  FetchPublicSessionArgs;

export function ConnectedPublicIconInfo({
  accessKey,
  nodeUid,
  sessionUid,
  versionUid,
}: ConnectedPublicIconInfoProps): ReactElement {
  const data = publicSessionApi.endpoints.getPublicSession.useQueryState(
    { accessKey, sessionUid, versionUid },
    {
      selectFromResult: ({ data }) =>
        getIconDataFromNodeDetails(
          data?.diagram.nodes.filter(
            (node) => node.uid === nodeUid,
          )[0] as SessionIconNode,
        ),
    },
  );

  return <IconInfo data={data.keyValuePairs} title={data.title} />;
}
