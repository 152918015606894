import React, { ReactElement } from 'react';
import { publicSessionApi } from '../../../../../../../../redux/views/session-view/session/api/publicApi';
import { getDnsFromSession } from './utils';
import { DNS } from '.';

export function ConnectedPublicDNS({
  accessKey,
  sessionUid,
  versionUid,
}: FetchPublicSessionArgs): ReactElement {
  const { dns, isLoading, isFetching, isError } =
    publicSessionApi.endpoints.getPublicSession.useQueryState(
      { accessKey, sessionUid, versionUid },
      {
        selectFromResult: ({ data, isLoading, isFetching, isError }) => ({
          dns: getDnsFromSession(data),
          isError,
          isFetching,
          isLoading,
        }),
      },
    );

  return (
    <DNS dns={dns} isLoading={isLoading || isFetching} isError={isError} />
  );
}
