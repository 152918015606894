import React, { ReactElement } from 'react';
import { useSessionUserContext } from '../../../../../../../../../../hooks/use-session-user-context';
import { ConnectedDCloudInternalIP } from './dCloudContainer';
import { ConnectedPublicInternalIP } from './publicContainer';

export function InternalIPContainer(): ReactElement {
  const { accessKey, sessionUid, versionUid } = useSessionUserContext();

  if (accessKey) {
    return (
      <ConnectedPublicInternalIP
        accessKey={accessKey}
        sessionUid={sessionUid}
        versionUid={versionUid}
      />
    );
  }

  return (
    <ConnectedDCloudInternalIP
      sessionUid={sessionUid}
      versionUid={versionUid}
    />
  );
}

export type InternalIpProps = {
  sessionInternalAddresses?: SessionInternalAddress[];
  isLoading: boolean;
  isError: boolean;
};
