import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { Tab, Tabs } from '../../../Tabs';
import { IconPickerTabContent } from '../IconPickerTabContent';
import { formatTabTitle } from './utils/utils';
import styles from './index.module.css';
import { IconPickerTabsProps } from './container';

export const IconPickerTabs = ({
  tabOrder = [],
  isHeightCondensed,
}: IconPickerTabsProps): ReactElement => {
  const ROOT_STYLES = classNames(styles.root, {
    [styles.heightCondensed]: isHeightCondensed,
  });

  return (
    <div className={ROOT_STYLES}>
      <Tabs id="icon-picker-tabs">
        {tabOrder.map((tabOrderItem) => {
          return (
            <Tab
              id={`icon-picker-tabs-tab-${tabOrderItem}`}
              title={formatTabTitle(tabOrderItem)}
              key={tabOrderItem}
            >
              <IconPickerTabContent tabTitle={tabOrderItem} />
            </Tab>
          );
        })}
      </Tabs>
    </div>
  );
};
