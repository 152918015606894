import React, { ReactElement } from 'react';
import { publicSessionApi } from '../../../../../../../../redux/views/session-view/session/api/publicApi';
import { AnyConnect } from '.';

export function ConnectedPublicAnyConnect({
  accessKey,
  sessionUid,
  versionUid,
}: FetchPublicSessionArgs): ReactElement {
  const { anyConnectNode, isFetching, isLoading, isError } =
    publicSessionApi.endpoints.getPublicSession.useQueryState(
      {
        accessKey,
        sessionUid,
        versionUid,
      },
      {
        selectFromResult: ({ data, isLoading, isFetching, isError }) => ({
          anyConnectNode: data?.sessionDetails.anyConnect,
          isError,
          isFetching,
          isLoading,
        }),
      },
    );

  return (
    <AnyConnect
      anyConnectNode={anyConnectNode}
      isLoading={isLoading || isFetching}
      isError={isError}
    />
  );
}
