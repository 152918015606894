import React, { ReactElement } from 'react';
import { publicSessionApi } from '../../../../../../redux/views/session-view/session/api/publicApi';
import { ActionsBarTimerContainerProps } from './container';
import { ActionsBarTimer } from '.';

type ConnectedPublicActionsBarTimerProps = ActionsBarTimerContainerProps &
  FetchPublicSessionArgs;

export function ConnectedPublicActionsBarTimer({
  expired,
  setShowExpiredModal,
  setExpired,
  accessKey,
  sessionUid,
  versionUid,
}: ConnectedPublicActionsBarTimerProps): ReactElement {
  const { stop, isError } =
    publicSessionApi.endpoints.getPublicSession.useQueryState(
      { accessKey, sessionUid, versionUid },
      {
        selectFromResult: ({ data, isError }) => ({
          isError,
          stop: data?.sessionDetails.stop,
        }),
      },
    );

  if (!stop || isError) {
    return <></>;
  }

  return (
    <ActionsBarTimer
      expired={expired}
      stopDateString={stop}
      setExpired={setExpired}
      setShowExpiredModal={setShowExpiredModal}
    />
  );
}
