import { useEffect } from 'react';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

export type UseInitialiseCheckbox = {
  setIsCommonIconNodeHiddenInEditModalOnRender: ActionCreatorWithPayload<
    boolean,
    string
  >;
  setIsCommonIconNodeHiddenInEditModal: ActionCreatorWithPayload<
    boolean,
    string
  >;
  isHidden: boolean;
};

export const useInitialiseCheckbox = ({
  setIsCommonIconNodeHiddenInEditModalOnRender,
  setIsCommonIconNodeHiddenInEditModal,
  isHidden,
}: UseInitialiseCheckbox): void =>
  useEffect(() => {
    setIsCommonIconNodeHiddenInEditModalOnRender(isHidden);
    setIsCommonIconNodeHiddenInEditModal(isHidden);
  }, [
    setIsCommonIconNodeHiddenInEditModalOnRender,
    isHidden,
    setIsCommonIconNodeHiddenInEditModal,
  ]);
