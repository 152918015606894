import React, { ReactElement } from 'react';
import { publicSessionApi } from '../../../../../../../redux/views/session-view/session/api/publicApi';
import { getMorIdFromSessionDetails } from '../../../../utils';
import { PanelHeaderButtons } from '../index';
import { VmLeftPanelProps } from './container';

type ConnectedPublicVmLeftPanelHeaderButtonsProps = VmLeftPanelProps &
  FetchPublicSessionArgs;

export function ConnectedPublicVmLeftPanelHeaderButtons({
  deviceUid,
  accessKey,
  sessionUid,
  setLeftPanelType,
  versionUid,
}: ConnectedPublicVmLeftPanelHeaderButtonsProps): ReactElement {
  const { mor } = publicSessionApi.endpoints.getPublicSession.useQueryState(
    { accessKey, sessionUid, versionUid },
    {
      selectFromResult: ({ data }) => ({
        mor: getMorIdFromSessionDetails(
          (
            data?.diagram.nodes.filter(
              (node) => node.uid === deviceUid,
            )[0] as SessionVmNode
          ).sessionDetails,
        ),
      }),
    },
  );

  const { refetch } =
    publicSessionApi.endpoints.getPublicSessionVmStatus.useQuerySubscription({
      accessKey,
      mor,
      sessionUid,
      versionUid,
    });

  return (
    <PanelHeaderButtons
      refreshOnClick={refetch}
      closeOnClick={() => setLeftPanelType(undefined)}
    />
  );
}
