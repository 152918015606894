import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit/dist/createAction';
import { MutationDefinition, BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';

export type HandleOnSave = {
  display?: DiagramDisplay;
  selectedIcon?: string;
  nodeUid: string;
  updateNode: MutationTrigger<
    MutationDefinition<UpdateDiagramNodeProps, BaseQueryFn, string, DiagramNode>
  >;
  setCommonIconNodeTypeToEdit: ActionCreatorWithOptionalPayload<
    CommonDiagramIcons | undefined,
    string
  >;
  isCommonIconNodeHiddenInEditModal: boolean;
  isCommonIconNodeHiddenInEditModalDirty: boolean;
};

export const handleOnSave = async ({
  display,
  selectedIcon,
  nodeUid,
  updateNode,
  setCommonIconNodeTypeToEdit,
  isCommonIconNodeHiddenInEditModal,
  isCommonIconNodeHiddenInEditModalDirty,
}: HandleOnSave): Promise<void> => {
  const payload = {
    display: {
      ...display,
      icon: selectedIcon,
    },
    isInvalidateDiagramTagNeeded: true,
    nodeUid,
  };

  const hidden = determineHiddenValue({
    display,
    isCommonIconNodeHiddenInEditModal,
    isCommonIconNodeHiddenInEditModalDirty,
  });

  if (hidden !== undefined) {
    payload.display.hidden = hidden;
  }

  await updateNode(payload);
  setCommonIconNodeTypeToEdit(undefined);
};

type DetermineHiddenValue = {
  display: DiagramDisplay | undefined;
  isCommonIconNodeHiddenInEditModal: boolean;
  isCommonIconNodeHiddenInEditModalDirty: boolean;
};

export const determineHiddenValue = ({
  display,
  isCommonIconNodeHiddenInEditModal,
  isCommonIconNodeHiddenInEditModalDirty,
}: DetermineHiddenValue): boolean | undefined => {
  if (display === undefined) {
    return isCommonIconNodeHiddenInEditModal;
  }

  return display.hidden === undefined
    ? isCommonIconNodeHiddenInEditModalDirty
      ? isCommonIconNodeHiddenInEditModal
      : undefined
    : isCommonIconNodeHiddenInEditModal;
};

type DetermineIsSaveBtnDisabled = {
  existingIcon: string;
  selectedIcon?: string;
  isCommonIconNodeHiddenInEditModalDirty: boolean;
};

export const determineIsSaveBtnDisabled = ({
  existingIcon,
  selectedIcon,
  isCommonIconNodeHiddenInEditModalDirty,
}: DetermineIsSaveBtnDisabled): boolean =>
  existingIcon === selectedIcon && !isCommonIconNodeHiddenInEditModalDirty;
