import React, { ReactElement } from 'react';
import { Icon } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import styles from './index.module.css';

export const CommonIconEditModalCheckboxInfo = (): ReactElement => {
  const { t } = useTranslation();

  const ICON_CLASSES = classNames('half-margin-right', styles.icon);

  return (
    <div className={styles.root}>
      <Icon className={ICON_CLASSES} name="info-outline"></Icon>
      <span className={styles.text}>
        {t('diagram.modals.editCommonIcon.hideFromRunningSessionsInfo')}
      </span>
    </div>
  );
};
