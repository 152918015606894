import { Alert } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeading } from '../../../../components/PageHeading';
import { AccessRoleAssignmentDeleteModal } from './components/AccessRoleAssignmentDeleteModal';
import { AccessRoleAssignmentTable } from './components/AccessRoleAssignmentTable';
import { AccessRoleAssignmentProps } from './container';
import { AccessRoleAssignmentAddFormContainer } from './components/AccessRoleAssignmentAddForm/container';

export function AccessRoleAssignments({
  isError,
  isLoading,
  accessRoleAssignments,
  accessRoleAssignmentToDelete,
  setAccessRoleAssignmentToDelete,
}: AccessRoleAssignmentProps): ReactElement | null {
  const { t } = useTranslation();

  return (
    <section className="half-padding-left">
      <PageHeading pageTitle={t('accessRoleAssignment.name')} />
      {!isLoading ? <AccessRoleAssignmentAddFormContainer /> : null}
      {!accessRoleAssignments?.length && !isLoading ? (
        <Alert colour="info">{t('accessRoleAssignment.empty')}</Alert>
      ) : (
        <AccessRoleAssignmentTable
          accessRoleAssignments={accessRoleAssignments || []}
          isError={isError}
          isLoading={isLoading}
          setAccessRoleAssignmentToDelete={setAccessRoleAssignmentToDelete}
        />
      )}
      {accessRoleAssignmentToDelete && (
        <AccessRoleAssignmentDeleteModal
          setAccessRoleAssignmentToDelete={setAccessRoleAssignmentToDelete}
          accessRoleAssignment={accessRoleAssignmentToDelete}
        />
      )}
    </section>
  );
}
