import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../../../redux/store';
import { sessionViewSelectors } from '../../../../../../redux/views/session-view/session/slice';
import { useSessionUserContext } from '../../../../../../hooks/use-session-user-context';
import { ConnectedDCloudIconInfo } from './dCloudContainer';
import { ConnectedPublicIconInfo } from './publicContainer';

function IconInfoContainer({ nodeUid }: IconInfoContainerProps): ReactElement {
  const { accessKey, sessionUid, versionUid } = useSessionUserContext();

  if (accessKey) {
    return (
      <ConnectedPublicIconInfo
        accessKey={accessKey}
        nodeUid={nodeUid}
        sessionUid={sessionUid}
        versionUid={versionUid}
      />
    );
  }

  return (
    <ConnectedDCloudIconInfo
      nodeUid={nodeUid}
      sessionUid={sessionUid}
      versionUid={versionUid}
    />
  );
}

const mapStateToProps = (state: RootState) => ({
  nodeUid: sessionViewSelectors.getLeftPanelDeviceUid(state),
});

export type IconInfoContainerProps = ReturnType<typeof mapStateToProps>;

type IconInfoRTKProps = {
  data: PanelGridDataItem[];
};

export type IconInfoProps = IconInfoRTKProps & { title: string };

export const ConnectedIconInfoContainer =
  connect(mapStateToProps)(IconInfoContainer);
