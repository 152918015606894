import { DOCUMENTATION_PATH, PUBLIC_PATH } from '../../../api/paths';
import i18n from '../../../i18n';
import { dcloudApi } from '../../api';
import { TAGS } from '../../api/constants';

type PublicDocumentationArgs = {
  topologyUid: string;
  accessKey: string;
};

export const publicDocumentationApi = dcloudApi.injectEndpoints({
  endpoints: (build) => ({
    getPublicDocumentation: build.query<Documentation, PublicDocumentationArgs>(
      {
        keepUnusedDataFor: 0,
        providesTags: [TAGS.DOCUMENTATION],
        query: ({ accessKey, topologyUid }) => ({
          errorMessage: i18n.t('documentation.fetch.error'),
          method: 'get',
          url: `${PUBLIC_PATH}${DOCUMENTATION_PATH}/${topologyUid}?publicAccessKey=${accessKey}`,
        }),
      },
    ),
  }),
});

export const { useGetPublicDocumentationQuery } = publicDocumentationApi;
