import React, { ReactElement } from 'react';
import { useSessionUserContext } from '../../../../../../../../hooks/use-session-user-context';
import { ConnectedDCloudPhoneNumbers } from './dCloudContainer';
import { ConnectedPublicPhoneNumbers } from './publicContainer';

export function PhoneNumbersContainer(): ReactElement {
  const { accessKey, sessionUid, versionUid } = useSessionUserContext();

  if (accessKey) {
    return (
      <ConnectedPublicPhoneNumbers
        accessKey={accessKey}
        sessionUid={sessionUid}
        versionUid={versionUid}
      />
    );
  }

  return (
    <ConnectedDCloudPhoneNumbers
      sessionUid={sessionUid}
      versionUid={versionUid}
    />
  );
}

export type PhoneNumbersProps = {
  phoneNumbers?: PhoneNumberItem[];
  isLoading: boolean;
  isError: boolean;
};
