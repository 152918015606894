import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../../../../redux/store';
import { diagramApi } from '../../../../redux/diagram/api';
import { getViewsState } from '../../../../redux/views/slice';
import { diagramSelectors } from '../../../../redux/diagram/slice';
import { useSessionUserContext } from '../../../../hooks/use-session-user-context';
import { ConnectedDCloudSessionNodes } from './dCloudContainer';
import { ConnectedPublicSessionNodes } from './publicContainer';
import { Nodes } from '.';

export type NodesContainerProps = ReturnType<typeof mapStateToProps>;

function DiagramNodesContainer({
  isReadOnly,
  isAddingBox,
  isAddingLine,
  isAddingText,
  isSnapToGridEnabled,
  isAddingLogicalConnection,
}: NodesContainerProps): ReactElement {
  const { uid: topologyUid } = useParams<TopologyUid>();
  const { data } = diagramApi.endpoints.getDiagram.useQueryState(topologyUid);

  return (
    <Nodes
      nodes={data!.nodes}
      isReadOnly={isReadOnly}
      isAddingBox={isAddingBox}
      isAddingLine={isAddingLine}
      isAddingText={isAddingText}
      isSnapToGridEnabled={isSnapToGridEnabled}
      isAddingLogicalConnection={isAddingLogicalConnection}
    />
  );
}

export function SessionNodesContainer({
  isReadOnly,
  isAddingBox,
  isAddingLine,
  isAddingText,
}: NodesContainerProps): ReactElement {
  const { accessKey, sessionUid, versionUid } = useSessionUserContext();

  if (accessKey) {
    return (
      <ConnectedPublicSessionNodes
        isReadOnly={isReadOnly}
        isAddingBox={isAddingBox}
        isAddingLine={isAddingLine}
        isAddingText={isAddingText}
        accessKey={accessKey}
        sessionUid={sessionUid}
        versionUid={versionUid}
        isSnapToGridEnabled={false}
        isAddingLogicalConnection={false}
      />
    );
  }

  return (
    <ConnectedDCloudSessionNodes
      isReadOnly={isReadOnly}
      isAddingBox={isAddingBox}
      isAddingLine={isAddingLine}
      isAddingText={isAddingText}
      sessionUid={sessionUid}
      versionUid={versionUid}
      isSnapToGridEnabled={false}
      isAddingLogicalConnection={false}
    />
  );
}

const mapStateToProps = (state: RootState) => ({
  isAddingBox: diagramSelectors.getIsAddingBox(state),
  isAddingLine: diagramSelectors.getIsAddingLine(state),
  isAddingLogicalConnection:
    diagramSelectors.getIsAddingLogicalConnection(state),
  isAddingText: diagramSelectors.getIsAddingText(state),
  isReadOnly: getViewsState(state).isReadOnly,
  isSnapToGridEnabled: diagramSelectors.getIsSnapToGridEnabled(state),
});

export const ConnectedSessionNodes = connect(mapStateToProps)(
  SessionNodesContainer,
);

export const ConnectedDiagramNodes = connect(mapStateToProps)(
  DiagramNodesContainer,
);
