import React, { ReactElement } from 'react';
import { publicSessionApi } from '../../../../../../../../redux/views/session-view/session/api/publicApi';
import { buildIpAddressesForSessionInventoryVmData } from '../../../../../../SessionInventory/components/SessionInventoryVirtualMachines/utils';
import { IpAddressTableProps } from './container';
import { IpAddressesTable } from '.';

type ConnectedPublicIpAddressTableProps = IpAddressTableProps &
  FetchPublicSessionArgs;

export function ConnectedPublicIpAddressTable({
  accessKey,
  nodeUid,
  sessionUid,
  versionUid,
}: ConnectedPublicIpAddressTableProps): ReactElement {
  const { data: ipAddresses } =
    publicSessionApi.endpoints.getPublicSession.useQueryState(
      { accessKey, sessionUid, versionUid },
      {
        selectFromResult: ({ data }) => ({
          data: buildIpAddressesForSessionInventoryVmData(
            data?.diagram.nodes.filter(
              (node) => node.uid === nodeUid,
            )[0] as DiagramNodeVM,
          ),
        }),
      },
    );

  return <IpAddressesTable ipAddresses={ipAddresses} />;
}
