import React, { ReactElement } from 'react';
import { useSessionUserContext } from '../../../../../../../../../../hooks/use-session-user-context';
import { ConnectedDCloudProxy } from './dCloudContainer';
import { ConnectedPublicProxy } from './publicContainer';

export function ProxyContainer(): ReactElement {
  const { accessKey, sessionUid, versionUid } = useSessionUserContext();

  if (accessKey) {
    return (
      <ConnectedPublicProxy
        accessKey={accessKey}
        sessionUid={sessionUid}
        versionUid={versionUid}
      />
    );
  }

  return (
    <ConnectedDCloudProxy sessionUid={sessionUid} versionUid={versionUid} />
  );
}

export type ProxyProps = {
  sessionProxyAddresses?: SessionProxyAddress[];
  isLoading: boolean;
  isError: boolean;
};
