import React, { ReactElement } from 'react';
import { publicSessionApi } from '../../../../../../../../redux/views/session-view/session/api/publicApi';
import { getRemoteAccessFromNodeDetails } from './utils';
import { RemoteAccessContainerProps } from './container';
import { RemoteAccess } from '.';

type ConnectedPublicRemoteAccessProps = RemoteAccessContainerProps &
  FetchPublicSessionArgs;

export function ConnectedPublicRemoteAccess({
  accessKey,
  nodeUid,
  sessionUid,
  versionUid,
}: ConnectedPublicRemoteAccessProps): ReactElement {
  const { remoteAccessPanelData } =
    publicSessionApi.endpoints.getPublicSession.useQueryState(
      { accessKey, sessionUid, versionUid },
      {
        selectFromResult: ({ data }) => ({
          remoteAccessPanelData: getRemoteAccessFromNodeDetails(
            data?.diagram.nodes.filter(
              (node) => node.uid === nodeUid,
            )[0] as SessionVmNode,
          ),
        }),
      },
    );

  return <RemoteAccess data={remoteAccessPanelData || {}} />;
}
