import React, { ReactElement } from 'react';
import { publicSessionApi } from '../../../../../../../../redux/views/session-view/session/api/publicApi';
import { EndpointKits } from '.';

export function ConnectedPublicEndpointKits({
  accessKey,
  sessionUid,
  versionUid,
}: FetchPublicSessionArgs): ReactElement {
  const { endpoints, isLoading, isFetching, isError } =
    publicSessionApi.endpoints.getPublicSession.useQueryState(
      { accessKey, sessionUid, versionUid },
      {
        selectFromResult: ({ data, isLoading, isFetching, isError }) => ({
          endpoints: data?.sessionDetails.endpoints,
          isError,
          isFetching,
          isLoading,
        }),
      },
    );

  return (
    <EndpointKits
      endpoints={endpoints}
      isLoading={isLoading || isFetching}
      isError={isError}
    />
  );
}
