import React, { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { useSessionUserContext } from '../../../../hooks/use-session-user-context';
import { DCloudSessionViewNavigationButtons } from './dCloudIndex';

export type LocationPathname = {
  pathname: string;
};

export function SessionViewNavigationButtons(): ReactElement | null {
  const { accessKey, sessionUid, versionUid } = useSessionUserContext();

  const location = useLocation<{ to: string }>();

  if (accessKey) {
    return null;
  }

  return (
    <DCloudSessionViewNavigationButtons
      pathname={location.pathname}
      sessionUid={sessionUid}
      versionUid={versionUid}
    ></DCloudSessionViewNavigationButtons>
  );
}
