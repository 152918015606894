import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../../../../../redux/store';
import { sessionViewSelectors } from '../../../../../../../../redux/views/session-view/session/slice';
import { useSessionUserContext } from '../../../../../../../../hooks/use-session-user-context';
import { ConnectedPublicRemoteAccess } from './publicContainer';
import { ConnectedDCloudRemoteAccess } from './dCloudContainer';

export function RemoteAccess({
  nodeUid,
}: RemoteAccessContainerProps): ReactElement {
  const { accessKey, sessionUid, versionUid } = useSessionUserContext();

  if (accessKey) {
    return (
      <ConnectedPublicRemoteAccess
        accessKey={accessKey}
        nodeUid={nodeUid}
        sessionUid={sessionUid}
        versionUid={versionUid}
      />
    );
  }

  return (
    <ConnectedDCloudRemoteAccess
      nodeUid={nodeUid}
      sessionUid={sessionUid}
      versionUid={versionUid}
    />
  );
}

const mapStateToProps = (state: RootState) => ({
  nodeUid: sessionViewSelectors.getLeftPanelDeviceUid(state),
});

export type RemoteAccessContainerProps = ReturnType<typeof mapStateToProps>;

export type RemoteAccessProps = {
  data?: VmPanelRemoteAccess;
};

export const VmPanelRemoteAccessContainer =
  connect(mapStateToProps)(RemoteAccess);
