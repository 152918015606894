import React, { ReactElement } from 'react';
import { CenteredSpinner } from '../../../../../components';
import { publicSessionApi } from '../../../../../redux/views/session-view/session/api/publicApi';
import { retrieveVisibleConnections } from '../../../utils';
import { SessionDiagram } from '.';

export function ConnectedPublicSessionDiagram({
  accessKey,
  sessionUid,
  versionUid,
}: FetchPublicSessionArgs): ReactElement {
  const { data, isLoading } =
    publicSessionApi.endpoints.getPublicSession.useQueryState({
      accessKey,
      sessionUid,
      versionUid,
    });

  if (!data || isLoading) {
    return <CenteredSpinner />;
  }
  const { nodes, connections } = data.diagram;
  return (
    <SessionDiagram
      nodes={nodes}
      connections={retrieveVisibleConnections(connections, nodes)}
    />
  );
}
