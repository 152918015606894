import React, { ReactElement } from 'react';
import { publicSessionApi } from '../../../../../../../../../../redux/views/session-view/session/api/publicApi';
import { InternalIP } from '.';

export function ConnectedPublicInternalIP({
  accessKey,
  sessionUid,
  versionUid,
}: FetchPublicSessionArgs): ReactElement {
  const { sessionInternalAddresses, isLoading, isFetching, isError } =
    publicSessionApi.endpoints.getPublicSession.useQueryState(
      {
        accessKey,
        sessionUid,
        versionUid,
      },
      {
        selectFromResult: ({ data, isLoading, isFetching, isError }) => ({
          isError,
          isFetching,
          isLoading,
          sessionInternalAddresses:
            data?.sessionDetails.sessionInternalAddresses,
        }),
      },
    );

  return (
    <InternalIP
      sessionInternalAddresses={sessionInternalAddresses}
      isLoading={isLoading || isFetching}
      isError={isError}
    />
  );
}
