import React, { ReactElement } from 'react';
import { useSessionUserContext } from '../../../../../../../../hooks/use-session-user-context';
import { ConnectedDCloudDocuments } from './dCloudContainer';
import { ConnectedPublicDocuments } from './publicContainer';

export function DocumentsContainer(): ReactElement {
  const { accessKey, sessionUid, versionUid } = useSessionUserContext();

  if (accessKey) {
    return (
      <ConnectedPublicDocuments
        accessKey={accessKey}
        sessionUid={sessionUid}
        versionUid={versionUid}
      />
    );
  }
  return (
    <ConnectedDCloudDocuments sessionUid={sessionUid} versionUid={versionUid} />
  );
}
