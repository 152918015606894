import React, { ReactElement } from 'react';
import { Button } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import { MutationDefinition, BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { PrimaryButton } from '../../../../../../components/Buttons/PrimaryButton';
import { determineIsSaveBtnDisabled, handleOnSave } from './utils/utils';

export type CommonIconEditModalButtonsProps = {
  selectedIcon?: string;
  existingIcon: string;
  display?: DiagramDisplay;
  updateNode: MutationTrigger<
    MutationDefinition<UpdateDiagramNodeProps, BaseQueryFn, string, DiagramNode>
  >;
  nodeUid: string;
  isSaving?: boolean;
  setCommonIconNodeTypeToEdit: ActionCreatorWithOptionalPayload<
    CommonDiagramIcons | undefined,
    string
  >;
  isCommonIconNodeHiddenInEditModal: boolean;
  isCommonIconNodeHiddenInEditModalDirty: boolean;
};

export const CommonIconEditModalButtons = ({
  selectedIcon,
  existingIcon,
  display,
  updateNode,
  nodeUid,
  isSaving,
  setCommonIconNodeTypeToEdit,
  isCommonIconNodeHiddenInEditModalDirty,
  isCommonIconNodeHiddenInEditModal,
}: CommonIconEditModalButtonsProps): ReactElement => {
  const { t } = useTranslation();

  const handleOnSaveClick = async () => {
    await handleOnSave({
      display,
      isCommonIconNodeHiddenInEditModal,
      isCommonIconNodeHiddenInEditModalDirty,
      nodeUid,
      selectedIcon,
      setCommonIconNodeTypeToEdit,
      updateNode,
    });
  };

  const handleOnCancelClick = () => {
    setCommonIconNodeTypeToEdit(undefined);
  };

  const isSaveButtonDisabled = determineIsSaveBtnDisabled({
    existingIcon,
    isCommonIconNodeHiddenInEditModalDirty,
    selectedIcon,
  });

  return (
    <div className="base-padding-top flex flex-center">
      <Button
        type="button"
        size="large"
        colour="ghost"
        onClick={handleOnCancelClick}
      >
        {t('buttons.cancel')}
      </Button>
      <PrimaryButton
        onClick={handleOnSaveClick}
        loading={isSaving}
        disabled={isSaveButtonDisabled}
      >
        {t('buttons.save')}
      </PrimaryButton>
    </div>
  );
};
