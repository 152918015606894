import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import {
  resetMultiSelect,
  unsubscribeFromGetAllVmStatusesData,
} from '../../../redux/views/session-view/session-inventory/slice';
import { useSessionUserContext } from '../../../hooks/use-session-user-context';
import { ConnectedDCloudSession } from './dCloudContainer';
import { ConnectedPublicSession } from './publicContainer';

export type ConnectedSessionProps = {
  showPage: 'session-view' | 'session-inventory';
} & typeof mapDispatchToProps;

export type SessionProps = ConnectedSessionProps;

export const SessionContainer = ({
  showPage,
  unsubscribeFromGetAllVmStatusesData,
  resetMultiSelect,
}: ConnectedSessionProps): ReactElement => {
  const { accessKey, sessionUid, versionUid } = useSessionUserContext();

  if (accessKey) {
    return (
      <ConnectedPublicSession
        accessKey={accessKey}
        resetMultiSelect={resetMultiSelect}
        sessionUid={sessionUid}
        showPage={showPage}
        unsubscribeFromGetAllVmStatusesData={
          unsubscribeFromGetAllVmStatusesData
        }
        versionUid={versionUid}
      />
    );
  }

  return (
    <ConnectedDCloudSession
      resetMultiSelect={resetMultiSelect}
      sessionUid={sessionUid}
      showPage={showPage}
      unsubscribeFromGetAllVmStatusesData={unsubscribeFromGetAllVmStatusesData}
      versionUid={versionUid}
    />
  );
};

const mapDispatchToProps = {
  resetMultiSelect,
  unsubscribeFromGetAllVmStatusesData,
};

export const ConnectedSession = connect(
  null,
  mapDispatchToProps,
)(SessionContainer);
