import React, { ReactElement, useRef } from 'react';
import classNames from 'classnames';
import { Node } from '../Node';
import { useConnection } from '../Connections/hooks/use-connection';
import { useDrag } from '../Node/hooks/use-drag';
import { usePosition } from '../Node/hooks/use-position';
import { COMMON_DIAGRAM_ICONS } from '../../constants';
import { CommonIconEditModalContainer } from '../CommonIconEditModal/container';
import {
  VPODGW_ICON_PICKER_ICONS,
  VPODGW_TAB_ORDER,
} from '../../../../constants/iconPicker';
import { CommonIconEditButtonContainer } from '../CommonIconEditButton/container';
import styles from './VPodGateway.module.css';
import { DefaultVPodGateWayDisplay as defaultPosition } from './constants';
import { determineIconToRender } from './utils/utils';

export function VPodGateway({
  display,
  isReadOnly,
  uid,
  isSnapToGridEnabled,
  isAddingLogicalConnection,
}: BaseNodeProps): ReactElement {
  const nodeRef = useRef<HTMLDivElement>(null);
  const { redrawNodeConnections } = useConnection();

  const hookProps = {
    defaultPosition,
    display,
    nodeRef,
    redrawNodeConnections,
    uid,
  };

  usePosition({ ...hookProps });
  useDrag({ ...hookProps, isReadOnly, isSnapToGridEnabled });

  const SVG_CLASSES = classNames({
    [styles.svgCorrection]: display?.icon?.substring(0, 4) === 'Icon',
    [styles.svgOpacity]: display?.hidden,
  });

  const TITLE_CLASSES = classNames(styles.title, {
    [styles.hiddenOpacity]: display?.hidden,
  });

  const ACTIONS_BUTTON_WRAPPER_CLASSES = classNames(
    styles.actionButtonsWrapper,
    {
      [styles.hiddenOpacity]: display?.hidden,
    },
  );

  return (
    <>
      <Node
        isHidden={display?.hidden}
        isCommonNode={true}
        className={styles.root}
        ref={nodeRef}
        isReadOnly={isReadOnly}
        uid={uid}
        isConnectionAllowed={false}
        isAddingLogicalConnection={isAddingLogicalConnection}
        iconName={display?.icon}
      >
        <p className={TITLE_CLASSES}>vPodGW</p>
        <div className={ACTIONS_BUTTON_WRAPPER_CLASSES}>
          <CommonIconEditButtonContainer
            isAddingLogicalConnection={isAddingLogicalConnection || false}
            isReadOnly={isReadOnly}
            name={COMMON_DIAGRAM_ICONS.VPODGW}
          />
        </div>
        <span className={SVG_CLASSES}>
          {determineIconToRender({
            display,
            uid,
          })}
        </span>
      </Node>
      <CommonIconEditModalContainer
        nodeUid={uid}
        display={display}
        availableIcons={VPODGW_ICON_PICKER_ICONS}
        tabOrder={VPODGW_TAB_ORDER}
        existingIcon={display?.icon || 'RouterIcon'}
        nodeType={COMMON_DIAGRAM_ICONS.VPODGW}
      />
    </>
  );
}
