import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { setLeftPanelType } from '../../../../../../../redux/views/session-view/session/slice';
import { useSessionUserContext } from '../../../../../../../hooks/use-session-user-context';
import { ConnectedPublicIntegrationLeftPanelHeaderButtons } from './publicContainer';
import { ConnectedDCloudIntegrationLeftPanelHeaderButtons } from './dCloudContainer';

export function IntegrationLeftPanelHeaderButtons({
  setLeftPanelType,
}: IntegrationLeftPanelHeaderButtonsProps): ReactElement {
  const { accessKey, sessionUid, versionUid } = useSessionUserContext();

  if (accessKey) {
    return (
      <ConnectedPublicIntegrationLeftPanelHeaderButtons
        accessKey={accessKey}
        sessionUid={sessionUid}
        setLeftPanelType={setLeftPanelType}
        versionUid={versionUid}
      />
    );
  }

  return (
    <ConnectedDCloudIntegrationLeftPanelHeaderButtons
      sessionUid={sessionUid}
      setLeftPanelType={setLeftPanelType}
      versionUid={versionUid}
    />
  );
}

const mapDispatchToProps = {
  setLeftPanelType,
};

export type IntegrationLeftPanelHeaderButtonsProps = typeof mapDispatchToProps;

export const IntegrationLeftPanelHeaderButtonsContainer = connect(
  null,
  mapDispatchToProps,
)(IntegrationLeftPanelHeaderButtons);
