import React, { ReactElement } from 'react';
import { retrieveVisibleNodes } from '../../utils';
import { publicSessionApi } from '../../../../redux/views/session-view/session/api/publicApi';
import { NodesContainerProps } from './container';
import { Nodes } from '.';

type ConnectedPublicSessionNodesProps = NodesContainerProps &
  FetchPublicSessionArgs;

export function ConnectedPublicSessionNodes({
  isReadOnly,
  isAddingBox,
  isAddingLine,
  isAddingText,
  accessKey,
  sessionUid,
  versionUid,
  isSnapToGridEnabled,
}: ConnectedPublicSessionNodesProps): ReactElement {
  const { data } = publicSessionApi.endpoints.getPublicSession.useQueryState({
    accessKey,
    sessionUid,
    versionUid,
  });
  return (
    <Nodes
      isSnapToGridEnabled={isSnapToGridEnabled}
      nodes={retrieveVisibleNodes(data!.diagram.nodes)}
      isReadOnly={isReadOnly}
      isAddingBox={isAddingBox}
      isAddingLine={isAddingLine}
      isAddingText={isAddingText}
      isAddingLogicalConnection={false}
    />
  );
}
