import React, { ReactElement } from 'react';
import { publicSessionApi } from '../../../../../../../../../../redux/views/session-view/session/api/publicApi';
import { PublicIP } from '.';

export function ConnectedPublicPublicIP({
  accessKey,
  sessionUid,
  versionUid,
}: FetchPublicSessionArgs): ReactElement {
  const { sessionPublicAddresses, isLoading, isFetching, isError } =
    publicSessionApi.endpoints.getPublicSession.useQueryState(
      {
        accessKey,
        sessionUid,
        versionUid,
      },
      {
        selectFromResult: ({ data, isLoading, isFetching, isError }) => ({
          isError,
          isFetching,
          isLoading,
          sessionPublicAddresses: data?.sessionDetails.sessionPublicAddresses,
        }),
      },
    );

  return (
    <PublicIP
      sessionPublicAddresses={sessionPublicAddresses}
      isLoading={isLoading || isFetching}
      isError={isError}
    />
  );
}
