import React, { ReactElement } from 'react';
import { publicSessionApi } from '../../../../../../../../redux/views/session-view/session/api/publicApi';
import { useGetPublicDocumentationQuery } from '../../../../../../../../redux/configuration/documentation/publicApi';
import { Documents } from '.';

type PublicDocumentsProps = {
  accessKey: string;
  sessionUid: string;
  versionUid: string;
};

export function ConnectedPublicDocuments({
  accessKey,
  sessionUid,
  versionUid,
}: PublicDocumentsProps): ReactElement {
  const {
    documents,
    isLoading: isLoadingDocuments,
    isFetching: isFetchingDocuments,
    isError: isErrorDocuments,
    topologyUid,
  } = publicSessionApi.endpoints.getPublicSession.useQueryState(
    { accessKey, sessionUid, versionUid },
    {
      selectFromResult: ({ data, isLoading, isFetching, isError }) => ({
        documents: data?.sessionDetails.documents,
        isError,
        isFetching,
        isLoading,
        topologyUid: data?.topology.uid,
      }),
    },
  );

  const {
    data: documentation,
    isLoading: isLoadingDocumentation,
    isFetching: isFetchingDocumentation,
    isError: isErrorDocumentation,
  } = useGetPublicDocumentationQuery({
    accessKey,
    topologyUid: topologyUid!,
  });

  return (
    <Documents
      documents={documents}
      isLoading={
        isLoadingDocuments ||
        isFetchingDocuments ||
        isLoadingDocumentation ||
        isFetchingDocumentation
      }
      isError={isErrorDocuments || isErrorDocumentation}
      documentationUrl={documentation?.documentationUrl}
    />
  );
}
