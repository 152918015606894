import React, { ReactElement } from 'react';
import { useSessionUserContext } from '../../../../../hooks/use-session-user-context';
import { ConnectedDCloudDemoHeaderTitle } from './dCloudContainer';
import { ConnectedPublicDemoHeaderTitle } from './publicContainer';

export function DemoHeaderTitleContainer(): ReactElement {
  const { accessKey, sessionUid, versionUid } = useSessionUserContext();

  if (accessKey) {
    return (
      <ConnectedPublicDemoHeaderTitle
        accessKey={accessKey}
        sessionUid={sessionUid}
        versionUid={versionUid}
      />
    );
  }
  return (
    <ConnectedDCloudDemoHeaderTitle
      sessionUid={sessionUid}
      versionUid={versionUid}
    />
  );
}

export type DemoHeaderTitleProps = {
  demoTitle?: string;
  isLoading: boolean;
  isError: boolean;
};
