import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../../../../../redux/store';
import { sessionViewSelectors } from '../../../../../../../../redux/views/session-view/session/slice';
import { useSessionUserContext } from '../../../../../../../../hooks/use-session-user-context';
import { ConnectedPublicIpAddressTable } from './publicContainer';
import { ConnectedDCloudIpAddressTable } from './dCloudContainer';

export function IpAddressTable({ nodeUid }: IpAddressTableProps): ReactElement {
  const { accessKey, sessionUid, versionUid } = useSessionUserContext();

  if (accessKey) {
    return (
      <ConnectedPublicIpAddressTable
        accessKey={accessKey}
        nodeUid={nodeUid}
        sessionUid={sessionUid}
        versionUid={versionUid}
      />
    );
  }

  return (
    <ConnectedDCloudIpAddressTable
      nodeUid={nodeUid}
      sessionUid={sessionUid}
      versionUid={versionUid}
    />
  );
}

const mapStateToProps = (state: RootState) => ({
  nodeUid: sessionViewSelectors.getLeftPanelDeviceUid(state),
});

export type IpAddressTableProps = ReturnType<typeof mapStateToProps>;

export const IpAddressesTableContainer =
  connect(mapStateToProps)(IpAddressTable);
