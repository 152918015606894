import React, { ReactElement } from 'react';
import { useSessionUserContext } from '../../../../../../../../../../hooks/use-session-user-context';
import { DCloudAttachmentContainer } from './dCloudContainer';
import { PublicAttachmentContainer } from './publicContainer';

type AttachmentContentContainerProps = {
  sessionDocument: SessionDocument;
  key: number;
};

export function AttachmentContainer({
  sessionDocument,
}: AttachmentContentContainerProps): ReactElement {
  const { accessKey, versionUid } = useSessionUserContext();

  if (accessKey) {
    return (
      <PublicAttachmentContainer
        accessKey={accessKey}
        sessionDocument={sessionDocument}
        versionUid={versionUid}
      ></PublicAttachmentContainer>
    );
  }

  return (
    <DCloudAttachmentContainer
      sessionDocument={sessionDocument}
      versionUid={versionUid}
    ></DCloudAttachmentContainer>
  );
}
