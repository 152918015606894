import i18n from '../i18n';

export const USER_GUIDE_URL =
  'https://content.ciscodcloud.com/content/cisco-dcloud/us/en/topology-builder/tb-user-guide.html';

export const USER_GUIDE_IDs = {
  CONCEPT_TOPICS: {
    ABOUT: 'about',
    CREATE_TOPOLOGY: 'create',
    EXTERNAL_DNS: 'external-dns',
    GENERAL: 'configure-general',
    HARDWARE: 'configure-hardware',
    LANDING_PAGE: 'landing-page',
    LICENCES: 'licenses',
    MAIL_SERVER: 'mail-server',
    MY_TOPOLOGIES: 'detailed-view',
    NAT_RULES: 'nat',
    REMOTE_ACCESS: 'remote-access',
    SCENARIOS: 'scenarios',
    VIRTUAL_MACHINES: 'vms',
  },
  TASK_TOPICS: {
    ADD_HARDWARE: 'add-hardware',
    ADD_LICENSE: 'add-license',
    ADD_NETWORK: 'add-network',
    ADD_VM: 'add-vm',
    HW_START: 'hardware-start',
    VM_START: 'vm-start',
    VM_STOP: 'vm-stop',
  },
};

export const DEFAULT_HELP_LINKS: HelpButtonLink[] = [
  {
    ariaLabel: i18n.t('helpButtonLinks.demoCreationHelp'),
    href: 'https://eurl.io/#PXrpeYNX0',
    label: i18n.t('helpButtonLinks.demoCreationHelp'),
  },
  {
    ariaLabel: i18n.t('helpButtonLinks.dCloudSupport'),
    href: 'https://dcloud2-rtp.cisco.com/support/',
    label: i18n.t('helpButtonLinks.dCloudSupport'),
  },
];

export const USER_GUIDE_LINK_TEMPLATE: HelpButtonLink = {
  ariaLabel: i18n.t('helpButtonLinks.userGuide'),
  href: USER_GUIDE_URL,
  label: i18n.t('helpButtonLinks.userGuide'),
};

export const GENERAL_HELP_LINKS: HelpButtonLink[] = [
  ...DEFAULT_HELP_LINKS,
  {
    ...USER_GUIDE_LINK_TEMPLATE,
    href: `${USER_GUIDE_URL}#${USER_GUIDE_IDs.CONCEPT_TOPICS.GENERAL}`,
  },
];

export const CREATE_TOPOLOGY_HELP_LINKS: HelpButtonLink[] = [
  ...DEFAULT_HELP_LINKS,
  {
    ...USER_GUIDE_LINK_TEMPLATE,
    href: `${USER_GUIDE_URL}#${USER_GUIDE_IDs.CONCEPT_TOPICS.CREATE_TOPOLOGY}`,
  },
];

export const HARDWARE_ADD_HELP_LINKS: HelpButtonLink[] = [
  ...DEFAULT_HELP_LINKS,
  {
    ...USER_GUIDE_LINK_TEMPLATE,
    href: `${USER_GUIDE_URL}#${USER_GUIDE_IDs.TASK_TOPICS.ADD_HARDWARE}`,
  },
];

export const HARDWARE_ALL_HELP_LINKS: HelpButtonLink[] = [
  ...DEFAULT_HELP_LINKS,
  {
    ...USER_GUIDE_LINK_TEMPLATE,
    href: `${USER_GUIDE_URL}#${USER_GUIDE_IDs.CONCEPT_TOPICS.HARDWARE}`,
  },
];

export const LICENCES_HELP_LINKS: HelpButtonLink[] = [
  ...DEFAULT_HELP_LINKS,
  {
    ...USER_GUIDE_LINK_TEMPLATE,
    href: `${USER_GUIDE_URL}#${USER_GUIDE_IDs.CONCEPT_TOPICS.LICENCES}`,
  },
];

export const NETWORK_ADD_HELP_LINKS: HelpButtonLink[] = [
  ...DEFAULT_HELP_LINKS,
  {
    ...USER_GUIDE_LINK_TEMPLATE,
    href: `${USER_GUIDE_URL}#${USER_GUIDE_IDs.TASK_TOPICS.ADD_NETWORK}`,
  },
];

export const REMOTE_ACCESS_HELP_LINKS: HelpButtonLink[] = [
  ...DEFAULT_HELP_LINKS,
  {
    ...USER_GUIDE_LINK_TEMPLATE,
    href: `${USER_GUIDE_URL}#${USER_GUIDE_IDs.CONCEPT_TOPICS.REMOTE_ACCESS}`,
  },
];

export const HW_START_HELP_LINKS: HelpButtonLink[] = [
  ...DEFAULT_HELP_LINKS,
  {
    ...USER_GUIDE_LINK_TEMPLATE,
    href: `${USER_GUIDE_URL}#${USER_GUIDE_IDs.TASK_TOPICS.HW_START}`,
  },
];

export const VM_START_HELP_LINKS: HelpButtonLink[] = [
  ...DEFAULT_HELP_LINKS,
  {
    ...USER_GUIDE_LINK_TEMPLATE,
    href: `${USER_GUIDE_URL}#${USER_GUIDE_IDs.TASK_TOPICS.VM_START}`,
  },
];

export const VM_STOP_HELP_LINKS: HelpButtonLink[] = [
  ...DEFAULT_HELP_LINKS,
  {
    ...USER_GUIDE_LINK_TEMPLATE,
    href: `${USER_GUIDE_URL}#${USER_GUIDE_IDs.TASK_TOPICS.VM_STOP}`,
  },
];

export const EXTERNAL_DNS_HELP_LINKS: HelpButtonLink[] = [
  ...DEFAULT_HELP_LINKS,
  {
    ...USER_GUIDE_LINK_TEMPLATE,
    href: `${USER_GUIDE_URL}#${USER_GUIDE_IDs.CONCEPT_TOPICS.EXTERNAL_DNS}`,
  },
];

export const MAIL_SERVER_HELP_LINKS: HelpButtonLink[] = [
  ...DEFAULT_HELP_LINKS,
  {
    ...USER_GUIDE_LINK_TEMPLATE,
    href: `${USER_GUIDE_URL}#${USER_GUIDE_IDs.CONCEPT_TOPICS.MAIL_SERVER}`,
  },
];

export const NAT_RULES_HELP_LINKS: HelpButtonLink[] = [
  ...DEFAULT_HELP_LINKS,
  {
    ...USER_GUIDE_LINK_TEMPLATE,
    href: `${USER_GUIDE_URL}#${USER_GUIDE_IDs.CONCEPT_TOPICS.NAT_RULES}`,
  },
];

export const VM_ADD_HELP_LINKS: HelpButtonLink[] = [
  ...DEFAULT_HELP_LINKS,
  {
    ...USER_GUIDE_LINK_TEMPLATE,
    href: `${USER_GUIDE_URL}#${USER_GUIDE_IDs.TASK_TOPICS.ADD_VM}`,
  },
];

export const VM_HELP_LINKS: HelpButtonLink[] = [
  ...DEFAULT_HELP_LINKS,
  {
    ...USER_GUIDE_LINK_TEMPLATE,
    href: `${USER_GUIDE_URL}#${USER_GUIDE_IDs.CONCEPT_TOPICS.VIRTUAL_MACHINES}`,
  },
];

export const SCENARIOS_HELP_LINKS: HelpButtonLink[] = [
  ...DEFAULT_HELP_LINKS,
  {
    ...USER_GUIDE_LINK_TEMPLATE,
    href: `${USER_GUIDE_URL}#${USER_GUIDE_IDs.CONCEPT_TOPICS.SCENARIOS}`,
  },
];

export const HOME_HELP_LINKS: HelpButtonLink[] = [
  ...DEFAULT_HELP_LINKS,
  {
    ...USER_GUIDE_LINK_TEMPLATE,
    href: `${USER_GUIDE_URL}#${USER_GUIDE_IDs.CONCEPT_TOPICS.LANDING_PAGE}`,
  },
];

export const MY_TOPOLOGIES_HELP_LINKS: HelpButtonLink[] = [
  ...DEFAULT_HELP_LINKS,
  {
    ...USER_GUIDE_LINK_TEMPLATE,
    href: `${USER_GUIDE_URL}#${USER_GUIDE_IDs.CONCEPT_TOPICS.MY_TOPOLOGIES}`,
  },
];
