export enum MainRoutes {
  CreateTopology = '/create-topology',
  Edit = '/edit/:uid',
  GenericError = '/generic-error',
  Main = '/',
  NotAuthorisedGenericError = '/na-generic-error',
  CreateIntegration = '/integrations/add',
  IDP_CALLBACK = '/authenticate',
  EditIntegration = '/integrations/:integrationUid',
  Integrations = '/integrations',
  Sessions = '/sessions/:sessionUid',
  SessionsPublic = '/sessions-public/:sessionUid',
  SessionsInventory = '/sessions-inventory/:sessionUid',
  Templates = '/templates',
  Topologies = '/topologies',
  Videos = '/videos',
}

export enum CreateTopologyRoutes {
  DataCenter = '/create-topology',
  DemoImport = '/create-topology/demo-import',
  DemoType = '/create-topology/demo-type',
  Details = '/create-topology/details',
}
