import React, { ReactElement } from 'react';
import { publicSessionApi } from '../../../../../../../../redux/views/session-view/session/api/publicApi';
import { getHwInfoFromNodeDetails } from './utils';
import { HwInfoContainerProps } from './container';
import { HWInfo } from '.';

type ConnectedPublicHWInfoProps = HwInfoContainerProps & FetchPublicSessionArgs;

export function ConnectedPublicHWInfo({
  accessKey,
  nodeUid,
  sessionUid,
  versionUid,
}: ConnectedPublicHWInfoProps): ReactElement {
  const { hWInfoData } =
    publicSessionApi.endpoints.getPublicSession.useQueryState(
      { accessKey, sessionUid, versionUid },
      {
        selectFromResult: ({ data }) => ({
          hWInfoData: getHwInfoFromNodeDetails(
            data?.diagram.nodes.filter(
              (node) => node.uid === nodeUid,
            )[0] as SessionHwNode,
          ),
        }),
      },
    );

  return <HWInfo data={hWInfoData || {}} />;
}
