import { connect } from 'react-redux';
import React, { ReactElement } from 'react';
import { getPublicInternalDocument } from '../../../../../../../../../../redux/views/slice';
import { Attachment } from './index';

const mapDispatchToProps = {
  getExpoInternalDocument: getPublicInternalDocument,
};

type PublicAttachmentContentContainerProps = {
  accessKey: string;
  sessionDocument: SessionDocument;
  versionUid: string;
} & typeof mapDispatchToProps;

export function PublicAttachmentContentContainer({
  accessKey,
  sessionDocument,
  versionUid,
  getExpoInternalDocument,
}: PublicAttachmentContentContainerProps): ReactElement {
  const getInternalDocumentPublic = () =>
    getExpoInternalDocument({
      accessKey,
      documentUid: sessionDocument.uid,
      versionUid,
    });
  return (
    <Attachment
      sessionDocument={sessionDocument}
      getInternalDocument={getInternalDocumentPublic}
    ></Attachment>
  );
}

export const PublicAttachmentContainer = connect(
  null,
  mapDispatchToProps,
)(PublicAttachmentContentContainer);
