import React, { ReactElement } from 'react';
import { useSessionUserContext } from '../../../../../../../../hooks/use-session-user-context';
import { ConnectedDCloudEndpointKits } from './dCloudContainer';
import { ConnectedPublicEndpointKits } from './publicContainer';

export function EndpointKitsContainer(): ReactElement {
  const { accessKey, sessionUid, versionUid } = useSessionUserContext();

  if (accessKey) {
    return (
      <ConnectedPublicEndpointKits
        accessKey={accessKey}
        sessionUid={sessionUid}
        versionUid={versionUid}
      />
    );
  }

  return (
    <ConnectedDCloudEndpointKits
      sessionUid={sessionUid}
      versionUid={versionUid}
    />
  );
}

export type EndpointKitsProps = {
  endpoints?: EndpointKit[];
  isLoading: boolean;
  isError: boolean;
};
