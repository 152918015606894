import { connect } from 'react-redux';
import {
  diagramSelectors,
  setIsCommonIconNodeHiddenInEditModal,
  setIsCommonIconNodeHiddenInEditModalOnRender,
} from '../../../../../../redux/diagram/slice';
import { RootState } from '../../../../../../redux/store';
import { CommonIconEditModalCheckbox } from '.';

export type CommonIconEditModalCheckboxContainerOwnProps = {
  isHidden?: boolean;
};

const mapStateToProps = (
  state: RootState,
  ownProps: CommonIconEditModalCheckboxContainerOwnProps,
) => ({
  isCommonIconNodeHiddenInEditModal:
    diagramSelectors.getIsCommonIconNodeHiddenInEditModal(state),
  ...ownProps,
});

const mapDispatchToProps = {
  setIsCommonIconNodeHiddenInEditModal,
  setIsCommonIconNodeHiddenInEditModalOnRender,
};

export type CommonIconEditModalCheckboxProps = ReturnType<
  typeof mapStateToProps
> &
  typeof mapDispatchToProps;

export const CommonIconEditModalCheckboxContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CommonIconEditModalCheckbox);
