/* eslint-disable import/namespace */
import React, { ReactElement, useRef } from 'react';
import classNames from 'classnames';
import { Node } from '../Node';
import { useConnection } from '../Connections/hooks/use-connection';
import { useDrag } from '../Node/hooks/use-drag';
import { usePosition } from '../Node/hooks/use-position';
import {
  INTERNET_ICON_PICKER_ICONS,
  INTERNET_TAB_ORDER,
} from '../../../../constants/iconPicker';
import { CommonIconEditModalContainer } from '../CommonIconEditModal/container';
import { COMMON_DIAGRAM_ICONS } from '../../constants';
import { CommonIconEditButtonContainer } from '../CommonIconEditButton/container';
import styles from './InternetCloudNode.module.css';
import { DefaultInternetCloudNodeDisplay as defaultPosition } from './constants';
import { determineIconToRender } from './utils/utils';

export function InternetCloudNode({
  display,
  isReadOnly,
  uid,
  isSnapToGridEnabled,
  isAddingLogicalConnection,
}: BaseNodeProps): ReactElement {
  const nodeRef = useRef<HTMLDivElement>(null);
  const { redrawNodeConnections } = useConnection();

  const hookProps = {
    defaultPosition,
    display,
    nodeRef,
    redrawNodeConnections,
    uid,
  };

  usePosition({ ...hookProps });
  useDrag({ ...hookProps, isReadOnly, isSnapToGridEnabled });

  const TITLE_CLASSES = classNames(styles.title, {
    [styles.hiddenOpacity]: display?.hidden,
  });

  const SVG_CLASSES = classNames({
    [styles.svgOpacity]: display?.hidden,
  });

  const ACTIONS_BUTTON_WRAPPER_CLASSES = classNames(
    styles.actionButtonsWrapper,
    {
      [styles.hiddenOpacity]: display?.hidden,
    },
  );

  return (
    <>
      <Node
        isCommonNode={true}
        isHidden={display?.hidden}
        className={styles.root}
        ref={nodeRef}
        isReadOnly={isReadOnly}
        uid={uid}
        isConnectionAllowed={false}
        isAddingLogicalConnection={isAddingLogicalConnection}
        iconName={display?.icon}
      >
        <p className={TITLE_CLASSES}>Internet</p>
        <div className={ACTIONS_BUTTON_WRAPPER_CLASSES}>
          <CommonIconEditButtonContainer
            isAddingLogicalConnection={isAddingLogicalConnection || false}
            isReadOnly={isReadOnly}
            name={COMMON_DIAGRAM_ICONS.INTERNET}
          />
        </div>
        <span className={SVG_CLASSES}>
          {determineIconToRender({ display, uid })}
        </span>
      </Node>
      <CommonIconEditModalContainer
        nodeUid={uid}
        display={display}
        availableIcons={INTERNET_ICON_PICKER_ICONS}
        tabOrder={INTERNET_TAB_ORDER}
        existingIcon={display?.icon || 'Cloud'}
        nodeType={COMMON_DIAGRAM_ICONS.INTERNET}
      />
    </>
  );
}
