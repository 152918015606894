import React, { ReactElement } from 'react';
import { useSessionUserContext } from '../../../../../../../../hooks/use-session-user-context';
import { ConnectedDCloudSessionInformation } from './dCloudContainer';
import { ConnectedPublicSessionInformation } from './publicContainer';
import { SessionInformation } from '.';

export function SessionInformationContainer(): ReactElement {
  const { accessKey, sessionUid, versionUid } = useSessionUserContext();

  if (accessKey) {
    return (
      <ConnectedPublicSessionInformation
        accessKey={accessKey}
        sessionUid={sessionUid}
        versionUid={versionUid}
      />
    );
  }

  return (
    <ConnectedDCloudSessionInformation
      sessionUid={sessionUid}
      versionUid={versionUid}
    />
  );
}

export type SessionInformationProps = {
  sessionInformation?: SessionInformation;
  isLoading: boolean;
  isError: boolean;
};
