import React, { ReactElement } from 'react';
import { publicSessionApi } from '../../../../../../redux/views/session-view/session/api/publicApi';
import { InfoLeftPanelProps } from './infoContainer';
import { PanelHeaderButtons } from '.';

type ConnectedPublicInfoLeftPanelHeaderButtonsProps = InfoLeftPanelProps &
  FetchPublicSessionArgs;

export function ConnectedPublicInfoLeftPanelHeaderButtons({
  setLeftPanelType,
  accessKey,
  sessionUid,
  versionUid,
}: ConnectedPublicInfoLeftPanelHeaderButtonsProps): ReactElement {
  const { refetch } =
    publicSessionApi.endpoints.getPublicSession.useQuerySubscription({
      accessKey,
      sessionUid,
      versionUid,
    });

  return (
    <PanelHeaderButtons
      refreshOnClick={refetch}
      closeOnClick={() => setLeftPanelType(undefined)}
    />
  );
}
