import React, { ReactElement } from 'react';
import {
  publicSessionApi,
  useGetPublicSessionVmStatusQuery,
} from '../../../../../../../../redux/views/session-view/session/api/publicApi';
import { getMorIdFromSessionDetails } from '../../../../../utils';
import { getInfoFromNodeDetails } from './utils';
import { InfoContainerProps } from './container';
import { Info } from '.';

type ConnectedPublicInfoProps = InfoContainerProps & FetchPublicSessionArgs;

export function ConnectedPublicInfo({
  accessKey,
  nodeUid,
  sessionUid,
  versionUid,
}: ConnectedPublicInfoProps): ReactElement {
  const { staticVmInfo, mor } =
    publicSessionApi.endpoints.getPublicSession.useQueryState(
      { accessKey, sessionUid, versionUid },
      {
        selectFromResult: ({ data }) => ({
          mor: getMorIdFromSessionDetails(
            (
              data?.diagram.nodes.filter(
                (node) => node.uid === nodeUid,
              )[0] as SessionVmNode
            ).sessionDetails,
          ),
          staticVmInfo: getInfoFromNodeDetails(
            data?.diagram.nodes.filter(
              (node) => node.uid === nodeUid,
            )[0] as SessionVmNode,
          ),
        }),
      },
    );

  const { dynamicVmInfo, isLoadingDynamicVmInfo, isFetchingDynamicVmInfo } =
    useGetPublicSessionVmStatusQuery(
      { accessKey, mor, sessionUid, versionUid },
      {
        selectFromResult: ({ data, isLoading, isFetching }) => ({
          dynamicVmInfo: {
            guestOs: data?.vmwareState.guestState,
            vmWareTools: data?.vmwareState.guestToolsState,
          },
          isFetchingDynamicVmInfo: isFetching,
          isLoadingDynamicVmInfo: isLoading,
        }),
      },
    );

  return (
    <Info
      staticVmInfo={staticVmInfo}
      dynamicVmInfo={dynamicVmInfo}
      isLoadingDynamicVmInfo={isLoadingDynamicVmInfo || isFetchingDynamicVmInfo}
    />
  );
}
