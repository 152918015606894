import React, { ReactElement } from 'react';
import {
  publicSessionApi,
  usePostPublicSessionVmActionMutation,
} from '../../../../../../../../redux/views/session-view/session/api/publicApi';
import { getMorIdFromSessionDetails } from '../../../../../utils';
import { ControlProps } from './container';
import { Control } from '.';

type ConnectedPublicControlProps = ControlProps & FetchPublicSessionArgs;

export function ConnectedPublicControl({
  accessKey,
  nodeUid,
  sessionUid,
  versionUid,
}: ConnectedPublicControlProps): ReactElement {
  const { mor } = publicSessionApi.endpoints.getPublicSession.useQueryState(
    { accessKey, sessionUid, versionUid },
    {
      selectFromResult: ({ data }) => ({
        mor: getMorIdFromSessionDetails(
          (
            data?.diagram.nodes.filter(
              (node) => node.uid === nodeUid,
            )[0] as SessionVmNode
          ).sessionDetails,
        ),
      }),
    },
  );
  const [postSessionVmAction] = usePostPublicSessionVmActionMutation();

  const postVmAction = (action: SessionVmActions) => {
    postSessionVmAction({
      accessKey,
      action,
      mor,
      sessionId: sessionUid,
      topologyVersion: { uid: versionUid },
    });
  };

  return <Control postVmAction={postVmAction} />;
}
