import axios, { AxiosResponse } from 'axios';
import { DOCUMENTS_PATH, PUBLIC_PATH } from '../../../paths';
import { API_PATH } from './apiPath';

const documentsEndpoint = `${API_PATH}${PUBLIC_PATH}${DOCUMENTS_PATH}`;

export async function getPublicDocument(
  documentUid: number,
  accessKey: string,
  versionUid: string,
): Promise<AxiosResponse<Blob>> {
  const endpoint = `${documentsEndpoint}/${documentUid}?versionUid=${versionUid}&publicAccessKey=${accessKey}`;

  return await axios.get<Blob>(endpoint, {
    headers: {},
    responseType: 'blob',
  });
}
