import React, { ReactElement } from 'react';
import { useGetPublicSessionQuery } from '../../../redux/views/session-view/session/api/publicApi';
import { ConnectedSessionProps } from './container';
import { Session } from '.';

type ConnectedPublicSessionProps = ConnectedSessionProps &
  FetchPublicSessionArgs;

export const ConnectedPublicSession = ({
  showPage,
  unsubscribeFromGetAllVmStatusesData,
  resetMultiSelect,
  accessKey,
  sessionUid,
  versionUid,
}: ConnectedPublicSessionProps): ReactElement => {
  useGetPublicSessionQuery({
    accessKey,
    sessionUid,
    versionUid,
  });

  return (
    <Session
      resetMultiSelect={resetMultiSelect}
      showPage={showPage}
      unsubscribeFromGetAllVmStatusesData={unsubscribeFromGetAllVmStatusesData}
    />
  );
};
