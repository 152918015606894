import React, { ReactElement } from 'react';
import { publicSessionApi } from '../../../../../../../redux/views/session-view/session/api/publicApi';
import { PanelHeaderButtons } from '../index';
import { IntegrationLeftPanelHeaderButtonsProps } from './container';

type ConnectedPublicIntegrationLeftPanelHeaderButtonsProps =
  IntegrationLeftPanelHeaderButtonsProps & FetchPublicSessionArgs;

export function ConnectedPublicIntegrationLeftPanelHeaderButtons({
  accessKey,
  sessionUid,
  setLeftPanelType,
  versionUid,
}: ConnectedPublicIntegrationLeftPanelHeaderButtonsProps): ReactElement {
  const { refetch } =
    publicSessionApi.endpoints.getPublicSession.useQuerySubscription({
      accessKey,
      sessionUid,
      versionUid,
    });

  return (
    <PanelHeaderButtons
      refreshOnClick={refetch}
      closeOnClick={() => setLeftPanelType(undefined)}
    />
  );
}
