import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from '@reduxjs/toolkit';
import { publicSessionApi } from '../../../../../../redux/views/session-view/session/api/publicApi';
import { filterIntegrationsByType } from '../../../../../../utils/integrations';
import { TAGS } from '../../../../../../redux/api/constants';
import { IntegrationPanelContainerProps } from './container';
import { IntegrationPanel } from '.';

function PublicIntegrationPanel({
  accessKey,
  integrationType,
  onIntegrationTypeChange,
  sessionUid,
  versionUid,
}: PublicIntegrationPanelProps): ReactElement {
  const { sessionInformation, isError, isLoading, isFetching } =
    publicSessionApi.endpoints.getPublicSession.useQueryState(
      { accessKey, sessionUid, versionUid },
      {
        selectFromResult: ({ data, isLoading, isFetching, isError }) => ({
          isError,
          isFetching,
          isLoading,
          sessionInformation: data?.sessionDetails,
        }),
      },
    );
  return (
    <IntegrationPanel
      sessionIntegrationItems={filterIntegrationsByType(
        sessionInformation?.saasIntegrations,
        integrationType,
      )}
      isLoading={isLoading || isFetching}
      isError={isError}
      integrationType={integrationType}
      onIntegrationTypeChange={onIntegrationTypeChange}
    />
  );
}

const mapDispatchToProps = (dispatch: React.Dispatch<AnyAction>) => ({
  onIntegrationTypeChange: () =>
    dispatch(publicSessionApi.util.invalidateTags([TAGS.SESSIONS])),
});

type PublicIntegrationPanelProps = IntegrationPanelContainerProps &
  FetchPublicSessionArgs &
  ReturnType<typeof mapDispatchToProps>;

export const PublicIntegrationPanelContainer = connect(
  undefined,
  mapDispatchToProps,
)(PublicIntegrationPanel);
