import { connect } from 'react-redux';
import {
  reset,
  setAvailableIcons,
  setInitialSelectedIcon,
  setSelectedIcon,
  setShowButtons,
  setTabOrder,
} from '../../redux/iconPicker/slice';
import { RootState } from '../../redux/store';
import { IconPicker } from './index';

export type IconPickerOwnProps = {
  onCancelButtonClick?: VoidFunction;
  onPrimaryButtonClick?: (selection: string) => void;
  showButtons?: boolean;
  existingSelectedIcon?: string;
  availableIcons: IconPickerIcons;
  tabOrder: Array<string>;
  isHeightCondensed?: boolean;
};

const mapDispatchToProps = {
  reset,
  setAvailableIcons,
  setInitialSelectedIcon,
  setSelectedIcon,
  setShowButtons,
  setTabOrder,
};

const mapStateToProps = (_: RootState, ownProps: IconPickerOwnProps) => ({
  ...ownProps,
});

export type IconPickerProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const IconPickerContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(IconPicker);
