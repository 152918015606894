import React, { ReactElement } from 'react';
import { useSessionUserContext } from '../../../../../../../../hooks/use-session-user-context';
import { ConnectedDCloudDNS } from './dCloudContainer';
import { ConnectedPublicDNS } from './publicContainer';

export function DNSContainer(): ReactElement {
  const { accessKey, sessionUid, versionUid } = useSessionUserContext();

  if (accessKey) {
    return (
      <ConnectedPublicDNS
        accessKey={accessKey}
        sessionUid={sessionUid}
        versionUid={versionUid}
      />
    );
  }

  return <ConnectedDCloudDNS sessionUid={sessionUid} versionUid={versionUid} />;
}

export type DNSProps = {
  dns?: DnsItem[];
  dnsAssets?: DnsAsset[];
  createDnsTxtRecord?: RTKMutation<DnsTxtRecord, DnsTxtRecord> | undefined;
  isLoading: boolean;
  isUpdating?: boolean;
  isError: boolean;
};
