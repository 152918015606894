import React, { ReactElement } from 'react';
import { publicSessionApi } from '../../../../../../../../redux/views/session-view/session/api/publicApi';
import { getPhoneNumbersFromSession } from './utils';
import { PhoneNumbers } from '.';

export function ConnectedPublicPhoneNumbers({
  accessKey,
  sessionUid,
  versionUid,
}: FetchPublicSessionArgs): ReactElement {
  const { isError, isFetching, isLoading, phoneNumbersData } =
    publicSessionApi.endpoints.getPublicSession.useQueryState(
      { accessKey, sessionUid, versionUid },
      {
        selectFromResult: ({ data, isFetching, isLoading, isError }) => ({
          isError,
          isFetching,
          isLoading,
          phoneNumbersData: getPhoneNumbersFromSession(data),
        }),
      },
    );

  return (
    <PhoneNumbers
      isLoading={isFetching || isLoading}
      isError={isError}
      phoneNumbers={phoneNumbersData}
    />
  );
}
